import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering PageViewMetric related api calls

// METHODS
// ---- Get landing visit metrics
export function get_project_metrics(token) {
    const url = api.domain + generatePath(api.routes.getProjectMetrics)
    return makeRequest(url, Methods.GET, null, token)
}