import {routes} from "../config/routes";
import {kIcons} from "./icons";

export const navigationItems = [
    {
        name: "Accueil",
        route: routes.home,
        icon: kIcons.home

    },
    {
        name: "Navigation",
        route: routes.pageView,
        icon: kIcons.tour
    }
]