import { useLocation } from "react-router-dom";
import {computeNavigationItemColor} from "../../controllers/SystemNavigationItemController";
import {useNavigate} from "react-router";
import {useState} from "react";

function SystemSideBarNavigationItem({item}) {
    // Used in

    // PROPERTIES
    // ---- Navigation
    let navigate = useNavigate()
    const location = useLocation();
    const currentPath = location.pathname;
    // ---- Interaction
    const[isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={`cursor-pointer w-full flex justify-start items-center space-x-12 ${computeNavigationItemColor(currentPath, item.route, isHovered)}`}
             onClick={() => navigate(item.route)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
            <span className={`material-symbols-outlined `}>
                {item.icon}
            </span>
            <span className={`text-[18px] font-medium`}>
                {item.name}
            </span>
        </div>
    );
}

// EXPORT
export default SystemSideBarNavigationItem