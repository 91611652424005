import { legacy_createStore as createStore} from 'redux'
import {subtractDaysToDayString, todayDateString} from "./helpers/DateHelper";

// Define initial state
const initialState = {
    userEmail: null,
    startDate: subtractDaysToDayString(todayDateString(), 30),
    endDate: todayDateString(),
};

// Create a reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'updateUserEmail':
            return {...state, userName: action.payload};
        case 'updateStartDate':
            return { ...state, startDate: action.payload };
        case 'updateEndDate':
            return { ...state, endDate: action.payload };
        default:
            return state;
    }
};

// Create store
const store = createStore(reducer);

// Export
export default store;
