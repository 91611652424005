import {useDispatch, useSelector} from "react-redux";
import {addOneDayToDateString, subtractDaysToDayString, todayDateString} from "../../../helpers/DateHelper";
import {updateEndDate, updateStartDate} from "../../../actions/dates";
import PageViewDateForm from "../../forms/pageViewScreen/PageViewDateForm";

function PageViewTopBar() {
    // Used in

    // PROPERTIES
    // ---- Store
    const dispatch = useDispatch();
    // ---- Functional
    const startDate = useSelector(state => state.startDate);
    const startDateMin = "2024-08-15"
    const startDateMax = subtractDaysToDayString(todayDateString(), 1)
    const endDate = useSelector(state => state.endDate);
    const endDateMin = addOneDayToDateString(startDate) ?? ''
    const endDateMax = todayDateString()

    // BUILD
    return (
        <div className={"bg-backgroundLight5 w-full h-[56px] min-h-[56px] flex justify-between border-b-1 border-greyLight5 px-16"}>
            <PageViewDateForm startDate={startDate}
                              startDateMin={startDateMin}
                              startDateMax={startDateMax}
                              handleStartDateChange={handleStartDateChange}
                              endDate={endDate}
                              endDateMin={endDateMin}
                              endDateMax={endDateMax}
                              handleEndDateChange={handleEndDateChange} />
        </div>
    );

    // METHODS
    // ---- Handle start date change
    function handleStartDateChange(event) {
        dispatch(updateStartDate(event.target.value))
    }

    // ---- Handle end date change
    function handleEndDateChange(event) {
        dispatch(updateEndDate(event.target.value))
    }
}

// EXPORT
export default PageViewTopBar