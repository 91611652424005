
function HomeLevelAs({totalLevelAs}) {
    // Used in HomeScreen to display LevelA related
    // project statistics

    // BUILD
    return (
        <div className={"bg-backgroundLight5 w-[200px] min-w-[200px] px-16 py-12 rounded-[12px]"}>
            <span className={"text-[16px] text-greyLight11 font-medium"}>
                {"Niveaux A : " + totalLevelAs}
            </span>
        </div>
    );
}

// EXPORT
export default HomeLevelAs