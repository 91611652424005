import {useNavigate} from "react-router";
import {routes} from "../../config/routes";

function HomeLogoText() {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // PROPERTIES
    const navigate = useNavigate()

    // BUILD
    return (
        <div className={"w-full h-[56px] min-h-[56px] max-h-[56px] px-16 border-b-1 border-greyLight5 flex justify-center items-center"}>
            <div className={`animate-appear cursor-pointer`}
                 onClick={() => navigate(routes.home)}>
                <img className={"w-full"}
                     src={'/images/dashboardLogoText.png'}
                     alt="Logo" />
            </div>
        </div>

    );
}

// EXPORT
export default HomeLogoText