import {navigationItems} from "../../constants/navigation";
import SystemSideBarNavigationItem from "../items/SystemSideBarNavigationItem";

function SystemSideBarNavigationItemsList() {
    // Used in SystemSideBar to display navigation items list

    // BUILD
    return (
        <div className={"flex flex-col justify-start items-start px-16 space-y-16 mt-24"}>
            {navigationItems.map((item, index) => {
                return <SystemSideBarNavigationItem key={index}
                                                    item={item} />
            })}
        </div>
    );
}

// EXPORT
export default SystemSideBarNavigationItemsList