

// METHODS
// ---- Compute navigation item color based on current route and hover interaction
export function computeNavigationItemColor(currentPath, itemRoute, isHovered) {
    return currentPath === itemRoute
        ? "text-white"
        : isHovered
            ? "text-greyLight11"
            : "text-greyLight7"
}