import {kIcons} from "../../../constants/icons";

function AuthenticationPasswordInput({password, isPasswordVisible, setIsPasswordVisible, onPasswordChanged}) {
    // Used in AuthenticationScreen to display user identifier
    // input

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-6 justify-start items-start"}>
            <div className={"flex justify-start items-center space-x-6"}>
                <span className="material-symbols-outlined text-greyLight9 text-[20px]">
                    {kIcons.key}
                </span>
                <label className={"text-greyLight9 font-medium text-[16px]"}
                       htmlFor="password">
                    {"Mot de passe"}
                </label>
            </div>
            <div className={"relative w-full"}>
                <input className={"w-full rounded-[8px] py-6 pl-10 pr-16 focus:outline-none focus:ring-2 focus:ring-primaryDark1"}
                       type={isPasswordVisible ? "text" : "password"}
                       id="password"
                       value={password ?? ""}
                       onChange={(e) => onPasswordChanged(e.target.value)}
                       required />
                <span className="absolute cursor-pointer material-symbols-outlined top-[6px] right-[6px] text-greyLight5 hover:text-greyLight7"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)} >
                    {isPasswordVisible ? "visibility_off" : "visibility"}
                </span>
            </div>
        </div>
    );
}

// EXPORT
export default AuthenticationPasswordInput