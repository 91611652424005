
function SystemScreenError({problem, solution}) {
    // Used through the app to display error message in full screen
    // with both problem description and solution suggestion

    // BUILD
    return (
        <div className={"w-full h-screen flex flex-col justify-center items-center space-y-12"}>
            <span className={"text-[20px] text-white font-medium"}>
                {problem}
            </span>
            <span className={"text-[16px] text-greyLight9 font-medium"}>
                {solution}
            </span>
        </div>
    );
}

// EXPORT
export default SystemScreenError