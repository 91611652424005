import {Outlet, useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {getLocalItemAsJsonFromKey, kRefreshTokenKey, kTokenKey, setLocalItemFromKey} from "../services/LocalStorage";
import {isNonEmptyString, isNullOrUndefined} from "../services/Validator";
import {routes} from "../config/routes";
import SystemSideBar from "../components/bars/SystemSideBar";
import {refresh_token} from "../api/authentication";
import {updateUserEmail} from "../actions/user";
import {useDispatch} from "react-redux";
import SystemScreenLoader from "../components/loaders/SystemScreenLoader";

function WrapperScreen() {
    // Used in index.js as a meta wrapper to handle
    // authentication state. Use Outlet to render a child component
    // beside SideBar

    // PROPERTIES
    // ---- Store
    const dispatch = useDispatch();
    // ---- Navigation
    const navigate = useNavigate();
    const [isScreenLoading, setIsScreenLoading] = useState(true)

    // HOOKS
    // ---- Handle refreshToken/token validity
    useEffect(() => {
        checkAuthenticatedUser()
    }, [])

    // BUILD
    if (isScreenLoading)
        return <SystemScreenLoader />
    return (
        <div className={"bg-backgroundLight3 w-full h-screen flex"}>
            <SystemSideBar />
            <main className="flex-1 overflow-auto">
                <Outlet />
            </main>
        </div>
    );

    // METHODS
    // ---- Check if an authenticated user is connected
    function checkAuthenticatedUser() {
        // Switch loader on
        setIsScreenLoading(true)
        // Check refreshToken presence
        const refreshToken = getLocalItemAsJsonFromKey(kRefreshTokenKey)
        if (isNullOrUndefined(refreshToken) || !isNonEmptyString(refreshToken)) {
            // Case : No refreshToken found
            navigate(routes.authentication)
        } else {
            // Case : refreshToken found => refreshToken
            let data = {
                "refreshToken": refreshToken
            }
            refresh_token(data).then((res) => {
                if (!isNonEmptyString(res?.token) || !isNonEmptyString(res.refreshToken) || !isNonEmptyString(res?.user)) {
                    // Case : Response is KO => Navigate to authentication
                    navigate(routes.authentication)
                } else {
                    // Case : Response is OK
                    // ---- Store tokens in local storage and user email
                    setLocalItemFromKey(kTokenKey, res.token)
                    setLocalItemFromKey(kRefreshTokenKey, res.refreshToken)
                    dispatch(updateUserEmail(res.user))
                }
            }).catch(() => {
                // Navigate to authentication
                navigate(routes.authentication)
            }).finally(() => {
                // Switch loader on
                setIsScreenLoading(false)
            })
        }
    }

}

// EXPORT
export default WrapperScreen