import SystemCustomRadioButton from "../items/SystemCustomRadioButton";
import {EventName} from "../../enum/PageViewMetric";

function BarChartParameters({eventName, onEventNamePressed}) {
    // Used in PageBarchart to select current page view metric to display

    // BUILD
    return (
        <div className={"flex flex-col space-y-24 justify-start items-start -ml-8"}>
            {Object.values(EventName).map((e, index) => (
                <SystemCustomRadioButton key={index}
                                         label={e.name}
                                         checked={eventName.event === e.event}
                                         onPressed={() => onEventNamePressed(e)} />
            ))}
        </div>
    );
}

// EXPORT
export default BarChartParameters