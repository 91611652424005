import {isNonEmptyString, isNullOrUndefined} from "../services/Validator";
import {useState} from "react";
import {SystemPrimaryCustomButton} from "../components/buttons/SystemCustomButton";
import {kButtonStyles} from "../constants/styles";
import {log_user} from "../api/authentication";
import {setLocalItemFromKey, kTokenKey, kRefreshTokenKey} from "../services/LocalStorage";
import {useNavigate} from "react-router";
import {routes} from "../config/routes";
import {useDispatch} from "react-redux";
import {updateUserEmail} from "../actions/user";
import {kFakeToken} from "../services/Faker";
import AuthenticationTitleText from "../components/texts/authenticationScreen/AuthenticationTitleText";
import AuthenticationSubtitleText from "../components/texts/authenticationScreen/AuthenticationSubtitleText";
import AuthenticationIdInput from "../components/inputs/authenticationScreen/AuthenticationIdInput";
import AuthenticationPasswordInput from "../components/inputs/authenticationScreen/AuthenticationPasswordInput";

function AuthenticationScreen() {
    // Used in index.js to authenticate user for
    // dashboard access

    // PROPERTIES
    // ---- Navigation
    const navigate = useNavigate();
    // ---- Store
    const dispatch = useDispatch();
    // ---- UX
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    // ---- Functional
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full h-screen flex justify-center items-center"}>
            <div className={"bg-backgroundLight3 w-[400px] rounded-[16px] flex flex-col space-y-16 justify-center items-center py-16 px-24"}>
                <div className={"w-full flex flex-col justify-start items-center"}>
                    <AuthenticationTitleText />
                    <AuthenticationSubtitleText hasError={hasError} />
                </div>
                <div className={"w-full flex flex-col space-y-12"}>
                    <AuthenticationIdInput email={email}
                                           onEmailChanged={onEmailChanged} />
                    <AuthenticationPasswordInput password={password}
                                                 isPasswordVisible={isPasswordVisible}
                                                 setIsPasswordVisible={setIsPasswordVisible}
                                                 onPasswordChanged={onPasswordChanged} />
                </div>
                <SystemPrimaryCustomButton
                    label={"Se connecter"}
                    extraClass={"mt-[24px]"}
                    isDisabled={(!isNonEmptyString(email) || !isNonEmptyString(password))}
                    isLoading={isLoading}
                    isFullWidth={true}
                    onButtonClick={() => logUser()}
                    sizeStyle={kButtonStyles.mediumButtonStyle} />
            </div>
        </div>
    );

    // METHODS
    // ---- Log with provided credentials
    function logUser() {
        if (process.env.REACT_APP_FAKER === "true") {
            // Case : App run with FAKER mode
            // ---- Set environment variables
            setLocalItemFromKey(kTokenKey, kFakeToken.token)
            setLocalItemFromKey(kRefreshTokenKey, kFakeToken.refreshToken)
            dispatch(updateUserEmail(kFakeToken.user))
            // ---- Navigate to PageViewScreen
            navigate(routes.home)
        } else {
            // Defensive measure
            if (!isNonEmptyString(email) || !isNonEmptyString(password))
                return
            // Prepare
            setIsLoading(true)
            setHasError(false)
            let data = {
                "username": email,
                "password": password
            }
            // Execute
            log_user(data).then((res) => {
                if (isNonEmptyString(res?.token) && isNonEmptyString(res.refreshToken) && isNonEmptyString(res?.user)) {
                    // ---- Store tokens in local storage and user email
                    setLocalItemFromKey(kTokenKey, res.token)
                    setLocalItemFromKey(kRefreshTokenKey, res.refreshToken)
                    dispatch(updateUserEmail(res.user))
                    // ---- Navigate to PageViewScreen
                    navigate(routes.home)
                } else {
                    setHasError(true)
                }
            }).catch(() => {
                setHasError(true)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    // ---- On email changed
    function onEmailChanged(value) {
        if (isNullOrUndefined(value))
            return
        setEmail(value.trim())
    }

    // ---- On password changed
    function onPasswordChanged(value) {
        if (isNullOrUndefined(value))
            return
        setPassword(value.trim())
    }
}

// EXPORT
export default AuthenticationScreen