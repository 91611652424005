import {kIcons} from "../../../constants/icons";

function AuthenticationIdInput({email, onEmailChanged}) {
    // Used in AuthenticationScreen to display user identifier
    // input

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-6 justify-start items-start"}>
            <div className={"flex justify-start items-center space-x-6"}>
                <span className="material-symbols-outlined text-greyLight9 text-[20px]">
                    {kIcons.userId}
                </span>
                <label className={"text-greyLight9 font-medium text-[16px]"}
                       htmlFor="email">
                    {"Identifiant"}
                </label>
            </div>
            <input
                className={"w-full rounded-[8px] py-6 pl-10 pr-16 focus:outline-none focus:ring-2 focus:ring-primaryDark1"}
                type="text"
                id="email"
                value={email ?? ""}
                onChange={(e) => onEmailChanged(e.target.value)}
                required />
        </div>
    );
}

// EXPORT
export default AuthenticationIdInput