
function PageViewDateForm({ startDate,
                           startDateMin,
                           startDateMax,
                           handleStartDateChange,
                           endDate,
                           endDateMin,
                           endDateMax,
                           handleEndDateChange}) {
    // Used in SystemSideBar to display Date picker

    // BUILD
    return (
        <div className={"flex space-x-16"}>
            <div className={"flex justify-start items-center space-x-16"}>
                <span className={"w-[20px] font-medium text-greyLight11 text-[18px] leading-none"}>
                    {"Du"}
                </span>
                <input
                    className={"w-[144px] bg-greyLight11 px-6 py-2 text-grey font-medium border-1 border-backgroundLight5 rounded-[8px] "}
                    type="date"
                    value={startDate ?? ""}
                    onChange={handleStartDateChange}
                    min={startDateMin}
                    max={startDateMax} />
            </div>
            <div className={"flex justify-start items-center space-x-16"}>
                <span className={"w-[20px] font-medium text-greyLight11 text-[18px] leading-none"}>
                    {"au"}
                </span>
                <input
                    className={"w-[144px] bg-greyLight11 px-6 py-2 text-grey font-medium border-1 border-backgroundLight5 rounded-[8px]"}
                    type="date"
                    value={endDate ?? ""}
                    onChange={handleEndDateChange}
                    min={endDateMin}
                    max={endDateMax} />
            </div>
        </div>
    );
}

// TYPECHECKING
PageViewDateForm.propTypes = {}

// EXPORT
export default PageViewDateForm