
function AuthenticationTitleText() {
    // Used in AuthenticationScreen to display login
    // title text

    // BUILD
    return (
        <h1 className={"text-white font-medium text-[18px]"}>
            {"Se connecter au dashboard d'Ummia"}
        </h1>
    );
}

// EXPORT
export default AuthenticationTitleText