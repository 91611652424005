
function HomeQuestions({verifiedQuestions, totalQuestions}) {
    // Used in HomeScreen to display Questions related
    // project statistics

    // BUILD
    return (
        <div className={"bg-backgroundLight5 w-[200px] min-w-[200px] px-16 py-12 rounded-[12px]"}>
            <span className={"text-[16px] text-greyLight11 font-medium"}>
                {"Questions publiées : " + verifiedQuestions + " / " + totalQuestions}
            </span>
        </div>
    );
}

// EXPORT
export default HomeQuestions