import {isNullOrUndefined} from "../../services/Validator";

function SystemDesktopLayout({children}) {
    // Used in LandingScreen to frame content in desktop
    // mode

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-y-auto bg-backgroundLight3 w-full h-full hidden md:flex md:flex-col items-start justify-start py-32 px-48"}>
            <div className={"flex flex-col items-center justify-start"}>
                {children}
            </div>
        </div>
    );
}

// EXPORT
export default SystemDesktopLayout