import PageViewBarChartCard from "../components/cards/PageViewBarChartCard";
import SystemMobileTabletLayout from "../components/layouts/SystemMobileTabletLayout";
import SystemDesktopLayout from "../components/layouts/SystemDesktopLayout";
import PageViewTopBar from "../components/bars/pageViewScreen/PageViewTopBar";

function PageViewScreen() {
    // Used in index.js as the app entry point for default route

    // BUILD
    return (
        <div className="overflow-auto bg-backgroundLight3 w-full h-[100svh] flex flex-col">
            <SystemMobileTabletLayout />
            <PageViewTopBar />
            <SystemDesktopLayout>
                <PageViewBarChartCard />
            </SystemDesktopLayout>
        </div>
    );

}

// EXPORT
export default PageViewScreen