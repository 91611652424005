import HomeLogoText from "../images/HomeLogoText";
import SideBarUserCard from "../others/SideBarUserCard";
import {setLocalItemFromKey, kTokenKey} from "../../services/LocalStorage";
import {useNavigate} from "react-router";
import {routes} from "../../config/routes";
import SystemSideBarNavigationItemsList from "../lists/SystemSideBarNavigationItemsList";

function SystemSideBar() {
    // Used in PageViewScreen to display fixed Side bar for
    // parameters and navigation

    // PROPERTIES
    // ---- Navigation
    const navigate = useNavigate();


    // BUILD
    return (
        <aside className="bg-backgroundLight5 border-r-1 border-greyLight5 w-[220px] flex-shrink-0">
            <div className="h-full w-full font-medium text-greyLight11 flex flex-col justify-between">
                <div className={"flex flex-col justify-start items-start"}>
                    <HomeLogoText />
                    <SystemSideBarNavigationItemsList />
                </div>
                <SideBarUserCard logOut={logOut} />
            </div>
        </aside>
    );

    // METHODS
    // ---- Logout current user
    function logOut() {
        setLocalItemFromKey(kTokenKey, null)
        navigate(routes.authentication)
    }
}

// EXPORT
export default SystemSideBar