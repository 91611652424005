
function AuthenticationSubtitleText({hasError}) {
    // Used in AuthenticationScreen to display error
    // or subtitle text

    // BUILD
    if (hasError)
        return <span className={"text-red-400 font-normal text-[14px]"}>
                    {"Mauvais identifiant ou mot de passe"}
               </span>
    return <h2 className={"text-greyLight9 font-normal text-[14px]"}>
                {'Renseigner identifiant et mot de passe'}
           </h2>
}

// EXPORT
export default AuthenticationSubtitleText