import {getLocalItemAsJsonFromKey, kTokenKey} from "../services/LocalStorage";
import {useEffect, useState} from "react";
import {get_project_metrics} from "../api/projectMetric";
import SystemScreenLoader from "../components/loaders/SystemScreenLoader";
import SystemScreenError from "../components/errors/SystemScreenError";
import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../services/Validator";
import SystemDesktopLayout from "../components/layouts/SystemDesktopLayout";
import HomeLevelAs from "../components/others/homeScreen/HomeLevelAs";
import HomeSubthemes from "../components/others/homeScreen/HomeSubthemes";
import HomeQuestions from "../components/others/homeScreen/HomeQuestions";

function HomeScreen() {
    // Used in index.js as the component for home route

    // PROPERTIES
    // ---- Meta
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    // ---- Functional
    const [totalLevelAs, setTotalLevelAs] = useState(null)
    const [publishedSubthemes, setPublishedSubthemes] = useState(null)
    const [totalSubthemes, setTotalSubthemes] = useState(null)
    const [verifiedQuestions, setVerifiedQuestions] = useState(null)
    const [totalQuestions, setTotalQuestions] = useState(null)

    // HOOKS
    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        getProjectMetrics()
    }, []);

    // BUILD
    if (!isNullOrUndefined(error))
        return <SystemScreenError problem={error}
                                  solution={"Réessayer plus tard"} />
    if (isLoading)
        return <SystemScreenLoader />
    return (
        <SystemDesktopLayout>
            <HomeLevelAs totalLevelAs={totalLevelAs}/>
            <HomeSubthemes publishedSubthemes={publishedSubthemes}
                           totalSubthemes={totalSubthemes} />
            <HomeQuestions verifiedQuestions={verifiedQuestions}
                           totalQuestions={totalQuestions} />
        </SystemDesktopLayout>
    );

    // METHODS
    // ---- Get project metrics
    function getProjectMetrics() {
        const token = getLocalItemAsJsonFromKey(kTokenKey);
        // Reset state
        setIsLoading(true)
        setError(null)
        // Perform
        get_project_metrics(token)
            .then((res) => {
                if (isArrayNullUndefinedOrEmpty(res)) {
                    setError("Les données récupérées sont vides")
                } else {
                    setTotalLevelAs(res.totalLevelAs)
                    setPublishedSubthemes(res.publishedSubthemes)
                    setTotalSubthemes(res.totalSubthemes)
                    setVerifiedQuestions(res.verifiedQuestions)
                    setTotalQuestions(res.totalQuestions)
                }
            }).catch((err) => {
            console.error(err)
            setError("Erreur réseau, les données n'ont pas pu être récupérées")
        }).finally(() => {
            setIsLoading(false)
        })
    }
}

// EXPORT
export default HomeScreen